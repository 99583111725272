<template>
  <BaseExpandingWrapper
    v-if="globalsStore.site?.banner.isEnabled"
    :show="uiStore.showBanner"
  >
    <NuxtLink
      v-if="globalsStore.site?.banner.url"
      :to="globalsStore.site.banner.url"
      class="type-body-xs block bg-orange py-2 text-center"
    >
      <div class="gutters">
        {{ globalsStore.site.banner.text }}
      </div>
    </NuxtLink>
  </BaseExpandingWrapper>
</template>

<script lang="ts" setup>
const globalsStore = useGlobalsStore()
const uiStore = useUIStore()

if (globalsStore.site?.banner.isEnabled) {
  setTimeout(() => {
    uiStore.showBanner = true
  }, 2000)
}
</script>
